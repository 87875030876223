import { render, staticRenderFns } from "./EmployeeData.vue?vue&type=template&id=16bb9ce8&scoped=true&"
import script from "./EmployeeData.vue?vue&type=script&lang=js&"
export * from "./EmployeeData.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeData.vue?vue&type=style&index=0&id=16bb9ce8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16bb9ce8",
  null
  
)

export default component.exports